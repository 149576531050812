import type {ISettingsParam} from '@wix/tpa-settings'
import {
  isEventRsvpButtonVisible,
  isRegistrationClosed,
  isTicketed,
  isWithoutRegistration,
} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import {LinkToPage} from '../link-to-page'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  event,
  dataHook = 'ev-rsvp-button',
  navigateToPage,
  fullWidth,
  minWidth = 160,
  minHeight = 42,
  maxWidth,
  additionalStyle = {},
  hasUrl,
  eventRestricted,
  containerClassName,
  className,
}: RsvpButtonProps) => {
  const {getWithFallback} = useSettings()

  const visible = isEventRsvpButtonVisible(event)
  const text = getText(event, getWithFallback)

  const onClick = (e: React.MouseEvent) => {
    if (eventRestricted) {
      e.stopPropagation()
    }
    navigateToPage()
  }

  const styles: React.CSSProperties = {
    minWidth,
    maxWidth,
    minHeight,
    ...additionalStyle,
  }
  const classes = classNames(s.button, className, {
    [s.fullWidth]: fullWidth,
    [s.link]: hasUrl,
  })

  return visible ? (
    <div className={containerClassName}>
      {hasUrl ? (
        <LinkToPage
          event={event}
          dataHook={dataHook}
          style={styles}
          className={classes}
          allowExternal
          dataAnchor={DH.eventDetails}
        >
          {text}
        </LinkToPage>
      ) : (
        <button data-hook={dataHook} style={styles} className={classes} type="button" onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  ) : null
}

const getText = (
  event: wix.events.Event,
  getWithFallback: (param: ISettingsParam, fallbackParam: ISettingsParam) => any,
) => {
  const ticketed = isTicketed(event)
  const registrationClosed = isRegistrationClosed(event)
  const withoutRegistration = isWithoutRegistration(event)

  if (withoutRegistration) {
    return getWithFallback(settingsParams.multiNoRegButtonText, settingsParams.registrationClosedButtonText)
  }

  if (ticketed) {
    if (registrationClosed) {
      return getWithFallback(settingsParams.multiTicketedClosedBtnText, settingsParams.registrationClosedButtonText)
    } else {
      return getWithFallback(settingsParams.multiTicketedButtonText, settingsParams.listButtonText)
    }
  }

  if (registrationClosed) {
    return getWithFallback(settingsParams.multiRsvpClosedBtnText, settingsParams.registrationClosedButtonText)
  } else {
    return getWithFallback(settingsParams.multiRsvpButtonText, settingsParams.listButtonText)
  }
}
